//site
$primary-h: 210;
$primary-s: 100;
$primary-b: 68;

$unione: #007a52;

$primary: #0057ae;
$secondary: #30373d;
$tertiary: #5a6771;
$tertiary-text: white;

$header-slim-bg-color: $secondary;
$header-center-bg-color: white;

//$headings-color: $primary;
//$semibold: 600;

$link-color: $unione;
